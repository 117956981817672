import React from 'react'
import "./skills.css";
import ML from './ML';
import Cloud from './Cloud';
import img1 from '../../assets/cloud.png';
import img2 from '../../assets/terraform.png';

const Skills = () => {
  return (
    <section className="skills section" id="skills">
        <h2 className="section__title">Skills</h2>
        <span className="section__subtitle">My Technical Level</span>

        <div className="skills__container container grid">
            <Cloud />
            <ML />
        </div>

        <div className="skills__images">
                <img src={img1} alt="Cloud" className="skills__image"/>
                <img src={img2} alt="ML and AI" className="skills__image"/>
            </div>
    </section>
  )
}

export default Skills